// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `   .verifypcn-form {
    /* background-color: rgba(255, 255, 255, 0.8); */
    background: rgba(255, 255, 255, 0.5);
    /* background: rgba(255, 255, 255, 0.25); */
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    margin-bottom: 20px;
    max-width: 233px;
  }

  .verifypcn-input {
    padding: 5px 10px;
    margin-left: 10px;
    border-radius: 5px;
    border: none;
    background-color: #f2f2f2;
  }

  .Token-input {
    margin-top: 5px;
  }
  
  .verifypcn-button {
    padding: 5px 10px;
    margin-left: 10px;
    border-radius: 5px;
    border: none;
    background-color: #333;
    color: #fff;
    cursor: pointer;
  }
  
  .verifypcn-button:hover {
    background-color: #666;
  }

  .success-message {
    color: green;
    margin-top: 10px;
  }
  
  .error-message {
    color: red;
    margin-top: 10px;
  }`, "",{"version":3,"sources":["webpack://./src/components/pages/VerifyPCN/VerifyPCN.css"],"names":[],"mappings":"GAAG;IACC,gDAAgD;IAChD,oCAAoC;IACpC,2CAA2C;IAC3C,gDAAgD;IAChD,2BAA2B;IAC3B,mCAAmC;IACnC,mBAAmB;IACnB,2CAA2C;IAC3C,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,mBAAmB;IACnB,gBAAgB;EAClB;;EAEA;IACE,iBAAiB;IACjB,iBAAiB;IACjB,kBAAkB;IAClB,YAAY;IACZ,yBAAyB;EAC3B;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,iBAAiB;IACjB,iBAAiB;IACjB,kBAAkB;IAClB,YAAY;IACZ,sBAAsB;IACtB,WAAW;IACX,eAAe;EACjB;;EAEA;IACE,sBAAsB;EACxB;;EAEA;IACE,YAAY;IACZ,gBAAgB;EAClB;;EAEA;IACE,UAAU;IACV,gBAAgB;EAClB","sourcesContent":["   .verifypcn-form {\n    /* background-color: rgba(255, 255, 255, 0.8); */\n    background: rgba(255, 255, 255, 0.5);\n    /* background: rgba(255, 255, 255, 0.25); */\n    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);\n    backdrop-filter: blur(15px);\n    -webkit-backdrop-filter: blur(15px);\n    border-radius: 10px;\n    border: 1px solid rgba(255, 255, 255, 0.18);\n    padding: 20px;\n    border-radius: 10px;\n    text-align: center;\n    margin-bottom: 20px;\n    max-width: 233px;\n  }\n\n  .verifypcn-input {\n    padding: 5px 10px;\n    margin-left: 10px;\n    border-radius: 5px;\n    border: none;\n    background-color: #f2f2f2;\n  }\n\n  .Token-input {\n    margin-top: 5px;\n  }\n  \n  .verifypcn-button {\n    padding: 5px 10px;\n    margin-left: 10px;\n    border-radius: 5px;\n    border: none;\n    background-color: #333;\n    color: #fff;\n    cursor: pointer;\n  }\n  \n  .verifypcn-button:hover {\n    background-color: #666;\n  }\n\n  .success-message {\n    color: green;\n    margin-top: 10px;\n  }\n  \n  .error-message {\n    color: red;\n    margin-top: 10px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
