// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `   .admin-panel {
    /* background-color: rgba(255, 255, 255, 0.8); */
    background: rgba(255, 255, 255, 0.5);
    /* background: rgba(255, 255, 255, 0.25); */
    /* box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37); */
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .admin-links {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  .admin-links a {
    color: #333;
    text-decoration: none;
    margin-right: 10px;
    padding: 5px 10px;
    border-radius: 5px;
    background-color: #f2f2f2;
  }
  
  .admin-links a:hover {
    background-color: #e6e6e6;
  }
  #disablebtn{
    background-color:#333;
    color:white;
  }`, "",{"version":3,"sources":["webpack://./src/components/pages/AdminPage/AdminPage.css"],"names":[],"mappings":"GAAG;IACC,gDAAgD;IAChD,oCAAoC;IACpC,2CAA2C;IAC3C,sDAAsD;IACtD,2BAA2B;IAC3B,mCAAmC;IACnC,mBAAmB;IACnB,2CAA2C;IAC3C,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,uBAAuB;IACvB,gBAAgB;IAChB,mBAAmB;EACrB;;EAEA;IACE,WAAW;IACX,qBAAqB;IACrB,kBAAkB;IAClB,iBAAiB;IACjB,kBAAkB;IAClB,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;EAC3B;EACA;IACE,qBAAqB;IACrB,WAAW;EACb","sourcesContent":["   .admin-panel {\n    /* background-color: rgba(255, 255, 255, 0.8); */\n    background: rgba(255, 255, 255, 0.5);\n    /* background: rgba(255, 255, 255, 0.25); */\n    /* box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37); */\n    backdrop-filter: blur(15px);\n    -webkit-backdrop-filter: blur(15px);\n    border-radius: 10px;\n    border: 1px solid rgba(255, 255, 255, 0.18);\n    padding: 20px;\n    border-radius: 10px;\n    text-align: center;\n    margin-bottom: 20px;\n  }\n  \n  .admin-links {\n    display: flex;\n    justify-content: center;\n    margin-top: 10px;\n    margin-bottom: 10px;\n  }\n  \n  .admin-links a {\n    color: #333;\n    text-decoration: none;\n    margin-right: 10px;\n    padding: 5px 10px;\n    border-radius: 5px;\n    background-color: #f2f2f2;\n  }\n  \n  .admin-links a:hover {\n    background-color: #e6e6e6;\n  }\n  #disablebtn{\n    background-color:#333;\n    color:white;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
