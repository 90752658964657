// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../public/images/page-not-found/page-not-found.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-not-found-container {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___}); 
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.page-not-found {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    /* background-color: #f5f5f5; */
    font-family: Arial, sans-serif;
    color: #7c7c7c;
  }
  
  .page-not-found h1 {
    font-size: 4rem;
    margin-bottom: 1rem;
  }
  
  .page-not-found p {
    font-size: 1.5rem;
    text-align: center;
    margin-bottom: 2rem;
  } 

.button {
    display: inline-block;
    padding: 1rem 2rem;
    background-color: #717476;
    color: #cbcbcb;
    text-decoration: none;
    border-radius: 0.5rem;
    transition: background-color 0.2s ease;
    font-weight: bold;
  }
  
  .button:hover {
    background-color: #9b9797;
    /* #2c3942 */
  }
  `, "",{"version":3,"sources":["webpack://./src/components/pages/PageNotFound/PageNotFound.css"],"names":[],"mappings":"AAAA;IACI,yDAAoF;EACtF,sBAAsB;EACtB,4BAA4B;EAC5B,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;AACf;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;IACb,+BAA+B;IAC/B,8BAA8B;IAC9B,cAAc;EAChB;;EAEA;IACE,eAAe;IACf,mBAAmB;EACrB;;EAEA;IACE,iBAAiB;IACjB,kBAAkB;IAClB,mBAAmB;EACrB;;AAEF;IACI,qBAAqB;IACrB,kBAAkB;IAClB,yBAAyB;IACzB,cAAc;IACd,qBAAqB;IACrB,qBAAqB;IACrB,sCAAsC;IACtC,iBAAiB;EACnB;;EAEA;IACE,yBAAyB;IACzB,YAAY;EACd","sourcesContent":[".page-not-found-container {\n    background-image: url('../../../../public/images/page-not-found/page-not-found.jpg'); \n  background-size: cover;\n  background-repeat: no-repeat;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  height: 100vh;\n}\n\n.page-not-found {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    height: 100vh;\n    /* background-color: #f5f5f5; */\n    font-family: Arial, sans-serif;\n    color: #7c7c7c;\n  }\n  \n  .page-not-found h1 {\n    font-size: 4rem;\n    margin-bottom: 1rem;\n  }\n  \n  .page-not-found p {\n    font-size: 1.5rem;\n    text-align: center;\n    margin-bottom: 2rem;\n  } \n\n.button {\n    display: inline-block;\n    padding: 1rem 2rem;\n    background-color: #717476;\n    color: #cbcbcb;\n    text-decoration: none;\n    border-radius: 0.5rem;\n    transition: background-color 0.2s ease;\n    font-weight: bold;\n  }\n  \n  .button:hover {\n    background-color: #9b9797;\n    /* #2c3942 */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
