// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.removepcn-form {
  /* background-color: rgba(255, 255, 255, 0.8); */
  background: rgba(255, 255, 255, 0.5);
  /* background: rgba(255, 255, 255, 0.25); */
  /* box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37); */
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  margin-bottom: 20px;
}

.removepcn-input {
  padding: 5px 10px;
  margin-left: 10px;
  border-radius: 5px;
  border: none;
  background-color: #f2f2f2;
}


.removepcn-button {
  padding: 5px 10px;
  margin-left: 10px;
  border-radius: 5px;
  border: none;
  background-color: #333;
  color: #fff;
  cursor: pointer;
}

.removepcn-button:hover {
  background-color: #666;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/RemovePCN/RemovePCN.css"],"names":[],"mappings":"AAAA;EACE,gDAAgD;EAChD,oCAAoC;EACpC,2CAA2C;EAC3C,sDAAsD;EACtD,2BAA2B;EAC3B,mCAAmC;EACnC,mBAAmB;EACnB,2CAA2C;EAC3C,aAAa;EACb,mBAAmB;EACnB,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,kBAAkB;EAClB,YAAY;EACZ,yBAAyB;AAC3B;;;AAGA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,kBAAkB;EAClB,YAAY;EACZ,sBAAsB;EACtB,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,sBAAsB;AACxB","sourcesContent":[".removepcn-form {\n  /* background-color: rgba(255, 255, 255, 0.8); */\n  background: rgba(255, 255, 255, 0.5);\n  /* background: rgba(255, 255, 255, 0.25); */\n  /* box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37); */\n  backdrop-filter: blur(15px);\n  -webkit-backdrop-filter: blur(15px);\n  border-radius: 10px;\n  border: 1px solid rgba(255, 255, 255, 0.18);\n  padding: 20px;\n  border-radius: 10px;\n  text-align: center;\n  margin-bottom: 20px;\n}\n\n.removepcn-input {\n  padding: 5px 10px;\n  margin-left: 10px;\n  border-radius: 5px;\n  border: none;\n  background-color: #f2f2f2;\n}\n\n\n.removepcn-button {\n  padding: 5px 10px;\n  margin-left: 10px;\n  border-radius: 5px;\n  border: none;\n  background-color: #333;\n  color: #fff;\n  cursor: pointer;\n}\n\n.removepcn-button:hover {\n  background-color: #666;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
